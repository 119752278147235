.main {
  text-align: left;
  padding: 0;
  border-bottom: 1px solid #636466;
}

.main-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  color: white;
}

.main-logo {
  height: 40px;
  margin: 0 15px;
}

.main-link {
  color: #000;
}

.container-scrollable {
  overflow-y: auto;
  flex: 1;
}

textarea:focus, input:focus{
  outline: none;
}