@font-face {
    font-family: 'pt_sansregular';
    src: url('PTS55F-webfont.eot');
    src: url('PTS55F-webfont.eot?#iefix') format('embedded-opentype'),
         url('PTS55F-webfont.woff') format('woff'),
         url('PTS55F-webfont.ttf') format('truetype'),
         url('PTS55F-webfont.svg#pt_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

/**
 * Myriad pro
 */
@font-face {
    font-family: 'Myriad Pro';
    src: url('PTS55F-webfont.eot');
    src: url('PTS55F-webfont.eot?#iefix') format('embedded-opentype'),
         url('PTS55F-webfont.woff') format('woff'),
         url('PTS55F-webfont.ttf') format('truetype'),
         url('PTS55F-webfont.svg#pt_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

/**
 * Myriad pro symlink
 */
 @font-face {
    font-family: 'Myriad Pro Regular';
    src: url('PTS55F-webfont.eot');
    src: url('PTS55F-webfont.eot?#iefix') format('embedded-opentype'),
         url('PTS55F-webfont.woff') format('woff'),
         url('PTS55F-webfont.ttf') format('truetype'),
         url('PTS55F-webfont.svg#pt_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
