/**
 * Goal Detail Tab Menu
 */
 .goal-tabs {
  color: #3f3f3f;
  font-family: "Myriad Pro";
  font-size: 14px;
  font-weight: 400;
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #c1c1c1;
  border-top: 1px solid #c1c1c1;
}

.goal-tabs .nav-item {
  padding: 0;
  margin: 0;
}

.goal-tabs .nav-link {
  list-style: none;
  padding: 0 20px;
  border-right: 1px solid #838383;
  height: 100%;
  line-height: 31px;
  border-radius: 0;
  color: #3f3f3f;
  border-bottom: 1px solid #c1c1c1;
  border-top: 1px solid #c1c1c1;
  margin-top: -1px;
  margin-bottom: -1px;
}

.goal-tabs.nav-pills .nav-link:hover,
.goal-tabs.nav-pills .nav-link.active, 
.goal-tabs.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #67a1c9;
}

.goal-tabs.nav-pills .nav-link.active:hover, 
.goal-tabs.nav-pills .show > .nav-link:hover {
  color: #fff;
}
