.settings-modal .modal-title {
  color: #404040;
}
.settings-modal {
  max-width: 887px;
  min-height: 500px;
}

.settings-modal .modal-body {
  padding: 0;
}

.settings .col {
  padding: 15px 20px;
}

.settings {
  font-size: 14px;
  font-weight: 400;
  padding: 1rem;
  font-family: "Myriad Pro";
  color: #666666;
}

.settings .header {
  text-transform: uppercase;
  color: #404040;
  padding-bottom: 40px;
}

.form-control {
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  height: 38px;
  min-width: 265px;
}

.btn-primary {
  background-color: #1c5499;
  border-color: #1c5499;
}