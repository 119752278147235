/**
 * Main Menu Styles
 */
 .main-menu {
   height: 100%;
 }
.main-menu ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  color: white;
  height: 100%;
}
.main-menu ul, .main-menu li {
	margin: 0;
	padding: 0;
}
.main-menu ul {
	background: #fff;
	list-style: none;
  width: 100%;
  border-right: 1px solid #808285;
  border-left: 1px solid #808285;
}
.main-menu li {
  border-right: 1px solid #808285;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  padding: 5px 0;
}
.main-menu li:last-child {
  border-right: none;
}
.main-menu a {
  padding: 2px 15px ;
	background: #fff;
	color: #000;
	display: flex;
  font-size: 15px;
  font-weight: 400;
  align-items: center;
  text-transform: uppercase;
}
.main-menu li:hover a {
  background: #fefefe;
  text-decoration: none;
}

.main-menu-item a:hover i:before {
  color: #000;
}

.main-menu-sub {
  padding: 2px 15px;
}

.main-menu-sub .btn {
  border-color: #808285;
  height: 23px;
  padding: 0 0.65rem;
  font-size: 15px;
  line-height: 17.5px;
}

.dropdown-toggle::after {
  color: #808285;
}