#icons .i-code {
  display: none;
}

#icons.showCodes .i-code {
  display: block;
}

.the-icons {
  text-align: left;
}