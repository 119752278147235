@font-face {
    font-family: 'pt_sansbold';
    src: url('PTS75F-webfont.eot');
    src: url('PTS75F-webfont.eot?#iefix') format('embedded-opentype'),
         url('PTS75F-webfont.woff') format('woff'),
         url('PTS75F-webfont.ttf') format('truetype'),
         url('PTS75F-webfont.svg#pt_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

/**
 * Alternative for myriad pro
 */
 @font-face {
    font-family: 'Myriad Pro Bold';
    src: url('PTS75F-webfont.eot');
    src: url('PTS75F-webfont.eot?#iefix') format('embedded-opentype'),
         url('PTS75F-webfont.woff') format('woff'),
         url('PTS75F-webfont.ttf') format('truetype'),
         url('PTS75F-webfont.svg#pt_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

