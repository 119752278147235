/**
 * Account Settings Profile
 */
.account-settings .form-group {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.account-settings .form-label {
  width: 30%;
  text-align: right;
  padding-right: 10px;
  color: #999999;
}

.account-settings .form-control,
.account-settings .form-avatar {
  width: 300px;
  color: #404040;
}

.account-settings .form-avatar {
  display: flex;
}

.account-settings .form-avatar .btn {
  color: #666666;
  font-family: "Myriad Pro";
  font-size: 14px;
  font-weight: 400;
  margin: 0 4px;
}

/** @todo replace */
.account-settings .form-avatar .btn-sep {
  width: 1px;
  height: 14px;
  border-right: 1px solid #666666;
  margin: 0.75rem 0.25rem;
}

.account-settings button[type=submit] {
  margin: 20px auto;
}

.settings-list.other-email-btns {
  display: flex;
  padding: 0;
}

.settings-list.other-email-btns .btn {
  margin-right: 20px;
}

.form-avatar .form-avatar-btns {
  display: flex;
  opacity: 0;
  transition: 0.3s;
}
.form-avatar:hover .form-avatar-btns,
.form-avatar:focus .form-avatar-btns {
  opacity: 1;
}

.account-settings .form-group .form-control {
  border: 0px solid #d9d9d9;
}

.account-settings .form-group:hover .form-control,
.account-settings .form-group .form-control:focus {
  border: 1px solid #d9d9d9;
} 

/**
 * Account Settings Billing
 */ 

.settings-row .header {
  border-bottom: 1px solid #666666;
  padding: 4px;
}

.settings-list {
  padding: 8px;
}

.settings-group {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.settings-group .label {
  color: #999999;
  font-family: "Myriad Pro";
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  width: 25%;
  text-align: right;
  padding: 7px 12px;
}

.settings-group .value {
  padding: 8px 12px;
}

.settings-list .btn {
  border: 1px solid #666666;
  height: 23px;
  color: #666666;
  font-family: "Myriad Pro";
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  padding: 0 5px;
  display: flex;
  align-items: center;
}

/**
 * Settings Dropdown
 */
.account-settings .settings-dropdown {
  border: 1px solid #666666;
  border-radius: 3px;
  height: 33px;
  min-width: 160px;
  display: flex;
  align-items: flex-start;
}

.account-settings .settings-dropdown .dropdown-menu {
  min-width: 160px;
}

.account-settings .settings-dropdown .btn {
  padding: 0 8px;
  height: 100%;
  width: 100%;
  text-align: left;
}

.account-settings .settings-dropdown .dropdown-toggle::after {
  color: #808285;
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -0.15em;
}

.account-settings .settings-dropdown,
.account-settings .settings-dropdown .btn,
.account-settings .settings-dropdown .dropdown-menu {
  color: #666666;
  font-family: "Myriad Pro";
  font-size: 14px;
  font-weight: 400;
}

.account-settings-billing .col:first-child {
  padding-bottom: 0;
}

.account-settings-billing a {
  color: #999999;
  text-decoration: underline;
}