.goal-detail-stats {
  display: flex;
}

.goal-stat {
  margin-left: 20px;
  padding: 6px 0 0 0;
}

.goal-stat-value {
  color: #3faaff;
  font-family: "Myriad Pro";
  font-size: 20px;
  font-weight: bold;
}

.goal-stat-status-success {
  color: #58fd28;
}

.goal-stat-status-warn {
  color: #fb0b16;
}

.goal-stat label {
  color: #666666;
  font-family: "Myriad Pro";
  font-size: 10px;
  font-weight: 400;
}

.goal-stat .stat-suffix {
  color: #666666;
  font-family: "Myriad Pro";
  font-size: 8px;
  font-weight: 400;
}
