/**
 * Admin Users Common
 */

.admin-user-modal {
  max-width: 84%;
  margin-top: 5%;
}

.admin-user-modal .modal-content {
  min-width: 900px;
}

.admin-user .header {
  padding-bottom: 0;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
}

.admin-user .table {
  border-radius: 2px;
  border-collapse: collapse;
  border-style: hidden;
  box-shadow: 0 0 0 2px #b4b4b4; 
}

.admin-user tbody tr td {
  padding: 0;
  min-height: 40px;
  vertical-align: middle;
}

.admin-user tbody tr td .btn-user-detail {
  background-color: transparent;
}

.admin-user .table-striped tbody tr:nth-of-type(odd) {
  background-color: #e1e7ea5e;
}

.admin-user .table-striped tbody tr:hover {
  background-color: #e1e7ea;
}

.admin-user footer .footer-btns {
  margin: 0 auto;
}

.admin-user footer .btn {
  margin: 0 5px;
}

.admin-user footer {
  display: flex;
  align-items: center;
}

.admin-user footer .pagination {
  margin: 0 0 0 auto;
}

.admin-user footer .footer-select-all {
  display: flex;
  align-items: center;
  margin: 0;
}

.admin-user footer .footer-select-all label {
  margin: 0;
  padding-left: 20px;
  min-width: 150px;
  cursor: pointer;
}

.admin-user footer .footer-select-all input {
  min-width: 20px;
  cursor: pointer;
}

.admin-user .btn {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
}

.admin-user .btn-primary {
  height: 31px;
  padding: 0 29px;
  border-radius: 2px;
  background-color: #68a2ca;
  border: none;
  color: #ffffff;
}

/**
 * Admin User List
 */

.admin-user tbody tr td:first-child,
.admin-user tbody tr td:nth-child(2) {
  text-align: center;
}

.admin-user tbody tr td:nth-child(2) input {
  margin: auto;
  width: 20px;
}

/**
 * User Search filters
 */

.admin-user-search {
  padding: 10px 0 20px;
}

.admin-user-search fieldset {
  border: 2px solid #b4b4b4;
  border-radius: 2px;
}

.admin-user-search fieldset legend {
  padding: 5px;
  width: auto;
  margin-left: 10px;
  font-size: 14px;
  margin-bottom: 0;
}

.admin-user-search .col {
  padding: 10px;
}

.admin-user-search .form-control,
.admin-user-search .form-control-dropdown {
  min-width: 100px;
  width: 100%;
  padding: 5px;
  height: 30px;
}

.admin-user-search .form-group {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.admin-user-search .form-group label {
  width: 40%;
  text-align: right;
  padding-right: 10px;
}

.admin-user-search .footer {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
}

.admin-user-search .footer .row {
  margin: 0;
  margin-left: 10px;
}

.admin-user-search .footer  .btn-search {
  background-color: #9fc6a8;
  border: 1px solid #88a98f;
}

.admin-user-search .footer  .btn-search:active {
  background-color: #88a98f;
  box-shadow: 0 0 0 0.2rem #9fc6a87d;
  border: 1px solid #88a98f;
}

.admin-user-search .footer  .btn-search:focus,
.admin-user-search .footer  .btn-search:active:focus {
  box-shadow: 0 0 0 0.2rem #9fc6a87d;
}

.admin-user-list .form-check {
  padding: 0;
}

.admin-user-list .input-borderless {
  padding: 0;
}

.admin-user-list .input-borderless:focus,
.admin-user-list .input-borderless:active {
  border: none;
  box-shadow: none;
}