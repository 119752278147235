/**
 * Form Control Dropdown
 */
 .form-control-dropdown {
  height: 36px;
  min-width: 300px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  color: #999999;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  display: flex;
  align-items: flex-start;
}

.form-control-dropdown .dropdown-menu {
  min-width: 300px;
}

.form-control-dropdown .btn {
  padding: 0 8px;
  height: 100%;
  width: 100%;
  text-align: left;
}

.form-control-dropdown .dropdown-toggle::after {
  color: #808285;
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -0.15em;
}

.form-control-dropdown,
.form-control-dropdown .btn,
.form-control-dropdown .dropdown-menu {
  color: #666666;
  font-family: "Myriad Pro";
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}