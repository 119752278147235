/**
 * Account register page
 */
.account-register .header {
  text-transform: none;
  color: #404040;
  font-size: 40px;
}

.account-register .info-text,
.account-register .help-text {
  width: 320px;
}

.account-register .info-text {
  color: #999;
  font-size: 18px;
  line-height: 21.6px;
}

.account-register .help-text {
  color: #999;
  font-size: 14px;
  line-height: 16.8px;
  padding-bottom: 30px;
}

.account-register .btn-resend-email {
  height: 30px;
  border-radius: 2px;
  border: 1px solid #666;
  padding: 0 20px;
  margin: 0;
  color: #666666;
  font-size: 14px;
}

.account-register .input-group {
  max-width: 500px;
}

.account-register .col {
  padding: 15px 20px;
}

/**
 * Common Header
 */
.centered-header .header {
  margin-left: 35%;
  padding-left: 20px;
  color: #3f3f3f;
  font-size: 24px;
  font-weight: 400;
  line-height: 28.8px;
  padding-bottom: 10px;
}

.centered-header .info-text {
  margin-left: 35%;
  padding-left: 20px;
  padding-bottom: 0;
  color: #999999;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  width: 100%;
}

/**
 * Common Form
 */

.account-register-form .form-group {
  display: flex;
  align-items: center;
}

.account-register-form .form-group .form-label {
  width: 35%;
  text-align: right;
  color: #666666;
  font-size: 14px;
  line-height: 16.8px;
  margin-bottom: 0;
 }

.account-register-form .form-group .form-control::placeholder {
  color: #999999;
}

.account-register-form .form-group .form-control {
  height: 36px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  color: #666666;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
}

.account-register-form .form-group .form-control,
.account-register-form .form-group .form-control-dropdown,
.account-register-form .form-group .form-control-profile-photo,
.account-register-form .form-group .form-control-info-text,
.account-register-form .form-group .form-control-value-text,
.account-register-form .form-group .form-control-card-meta {
  margin-left: 20px;
  max-width: 300px;
}

.account-register-form .form-btn-container,
.account-register-form .form-btn-container-add-teammate {
  width: 300px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-left: calc(35% + 20px);
  padding-top: 30px;
}

.account-register-form .btn-primary {
  height: 31px;
  padding: 0 29px;
  border-radius: 2px;
  background-color: #1c5499;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
}

.form-control-info-text .form-info-text {
  color: #999999;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
}

.form-control-value-text {
  width: 100%;
  text-align: right;
}

.account-register-form .form-group .form-control-card-meta .form-control.form-control-meta {
  max-width: 100px;
  min-width: 50px;
}

.account-register-form .form-group .form-control-card-meta .form-control.form-control-meta-month {
  width: 50px;
}

.account-register-form .form-group .form-control-card-meta .form-control-meta-year {
  min-width: 72px;
  width: 72px;
}

.account-register-form .form-group .form-control-card-meta .form-control.form-control-meta-cvv {
  width: 60px;
}

.account-register-form .form-group .form-control-card-meta {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 300px;
}

.account-register-form .form-group .form-control-card-meta .form-control:first-child {
  margin-left: 0;
}

.account-register-form .form-group .form-control-card-meta .cvv-label {
  margin-left: auto;
}

.form-control-dropdown .dropdown-toggle:disabled {
  background-color: #e9ecef;
  opacity: 1;
  border-radius: 2px;
}

/**
 * Register Email
 */
.account-register-email .col {
  padding: 40px 40px;
}

.account-register-email .info-text {
  padding-bottom: 60px;
}

/**
 * Photo Upload Form Control
 */
.form-control-profile-photo {
  display: flex;
  width: 300px;
}

.form-control-profile-photo .upload-photo-link {
  color: #666666;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
} 

.form-control-profile-photo .upload-photo-info {
  color: #999999;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
}

/**
 * Add Teammate
 */

.add-teammate-group {
  padding-bottom: 15px;
}

.account-register-form .form-btn-container-add-teammate {
  padding-top: 0;
}

.account-register-form .form-btn-container-add-teammate .btn {
  margin-top: -25px;
  color: #999999;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
}

.account-register-form .form-btn-container-add-teammate .btn:hover {
  text-decoration: underline;
}

/**
 * Start Free Trial
 */

.account-start-free-trial .header {
  color: #3f3f3f;
  font-size: 36px;
  font-weight: 400;
  line-height: 43.2px;
}

.account-start-free-trial .info-text {
  color: #999999;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.6px;
  margin-bottom: 23px;
}

.form-bordered {
  border: 1px solid #d9d9d9;
  background-color: #fafafa;
  padding: 25px;
  border-radius: 2px;
  margin-bottom: 20px;
}

.form-bordered .header {
  color: #333333;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  padding-bottom: 16px;
  padding-left: 40%;
  margin-left: -20px;
}

.form-bordered .form-group {
  margin-bottom: 10px;
}

.account-register-form-plan.form-bordered .form-control-dropdown {
  min-width: 200px;
  max-width: 100%;
}

.form-bordered .info-text {
  color: #999999;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  width: 100%;
  margin-top: 20px;
  padding-right: 5px;
}

.form-bordered .btn {
  width: 100%;
}

.form-bordered .btn-limited-version {
  color: #999999;
  font-size: 11px;
  font-weight: 400;
  line-height: 13.2px;
  background-color: #fafafa;
  margin-top: 10px;
}

.form-bordered .btn-limited-version:hover {
  text-decoration: underline;
}

.account-start-free-trial .col:first-child {
  padding-right: 0px;
}

.account-start-free-trial .account-register-form .form-group .form-control {
  min-width: 200px;
}

.account-start-free-trial .account-register-form .form-group .form-label {
  width: 40%;
}

.account-start-free-trial .account-register-form-checkout .form-group .form-label {
  width: 60%;
}

.account-register .footer .form-check {
  display: inline-block;
}

.account-register-profile .footer {
  margin: 0 auto;
  text-align: center;
}