.account-login .header {
  text-transform: none;
  color: #404040;
  font-size: 40px;
  text-align: center;
  margin-top: 40px;
}

.account-login .info {
  padding-bottom: 10px;
}

.account-login .form-control {
  max-width: 500px;
  margin: auto;
}

.account-login .col {
  text-align: center;
}

.account-login-profile .col {
  padding: 15px 20px;
}

.account-login-profile .form-control {
  max-width: 300px;
  margin: auto;
}

.account-login-profile .info {
  padding-bottom: 0;
}