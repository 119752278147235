/**
 * Account Settings Menu
 */
 .account-settings-menu {
  color: #404040;
  font-family: "Myriad Pro";
  font-size: 14px;
  font-weight: 400;
  padding: 0;
  margin: 0;
  height: 31px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #c1c1c1;
}

.account-settings-menu .nav-item {
  padding: 0;
  margin: 0;
}

.account-settings-menu .nav-link {
  list-style: none;
  padding: 0 20px;
  border-right: 1px solid #838383;
  height: 100%;
  line-height: 31px;
  border-radius: 0;
}

.account-settings-menu .nav-link:hover {
  color: #404040;
}

.account-settings-menu.nav-pills .nav-link.active, 
.account-settings-menu.nav-pills .show > .nav-link {
  background-color: #666666;
}

.account-settings-menu.nav-pills .nav-link.active:hover, 
.account-settings-menu.nav-pills .show > .nav-link:hover {
  color: #fff;
}