.goal-files {
  color: #666666;
  font-family: "Myriad Pro Regular", "Helvetica";
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  font-weight: 400;
}

.goal-files .file-item {
  margin: 0 20px;
  border-bottom: 2px solid #f2f2f2;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.goal-files .file-item .file-meta {
  margin-left: 15px;
}

.goal-files .file-item .file-col {
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.goal-files .file-item .file-col-6 {
  flex: 2;
}

.goal-files .file-item .file-name,
.goal-files .file-item a:hover {
  color: #666;
}
