/**
 * Treks Modal
 */

.modal .modal-header {
  background-color: #f2f2f2;
  height: 40px;
  padding: 0 1rem;
  display: flex;
  align-items: center;
}

.modal .modal-header .modal-title.h4 {
  font-family: "Myriad Pro";
  font-size: 15px;
  font-weight: 400;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal .modal-title-icon {
  margin-right: 10px;
}

.modal .modal-close-button {
  background-color: #f2f2f2;
  margin-right: -12px;
}

.modal-size-80 {
  max-width: 80%;
}

.modal-size-90 {
  max-width: 90%;
}