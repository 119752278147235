.btn-pressable {
  display: flex;
  align-items: center;
  justify-content: center;
    margin: 0;
    padding: 0;
    cursor: pointer;
    border: 0;
    background-color: transparent;
}

.btn-pressable:hover {
  text-decoration: none;
}