/**
 * Trek UI Base styles
 */

@import "./modal.css";
@import "../font/ptsans.css";

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
  font-family: Arial;
  font-size: 15px;
  font-weight: 400;
  line-height: 17.5px;
}

/**
 * App
 */
.main {
  font-family: "Myriad Pro", "Helvetica";
  color: #666;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a, i, i:before, a:before {
  -webkit-transition: all .15s ease;
	-moz-transition: all .15s ease;
	-ms-transition: all .15s ease;
	-o-transition: all .15s ease;
	transition: all .15s ease;
}

a {
  color: #555;
}

a:hover {
  color: #333;
  text-decoration: none;
}

/**
 * Flexible cols and rows with center align
 */
.row-flex {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.col-flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

/**
 * custom webkit scrollbar
 */
 .md-light-scrollbar::-webkit-scrollbar {
  width: 5px;
}
 
.md-light-scrollbar::-webkit-scrollbar-thumb {
  background: #dcdedf;
}

.md-light-scrollbar::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 5px;
}

.scrollbar-lg::-webkit-scrollbar {
  width: 8px;
}

.scrollbar-xlg::-webkit-scrollbar {
  width: 10px;
}

/**
 * Add ellipses to end of text overflow
 */
.text-overflow-ellipses {
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

/**
 * Editable text with non-visible input
 */
.input-borderless,
.form-control.input-borderless {
  border: none;
  outline: none;
  min-width: auto;
  background-color: transparent;
}

/**
 * Modals 
 */
 .modal-content {
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  border: 1px inset #fff;
}

/**
 * Forms 
 */
.form-control {
  height: 36px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  color: #999999;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
}

.btn-primary {
  background-color: #1c5499;
  border-color: #1c5499;
}

.center-text {
  text-align: center;
}

.dropdown-menu {
  max-height: calc(100vh - 20px);
  overflow-y: auto;
}

.bp3-toast-container {
  z-index: 1000000 !important;
}

/**
 * Forms
 */

.form-group {
  position: relative;
}

.invalid-feedback {
  position: absolute;
  display: inline-block;
  width: auto;
  bottom: 0;
  margin-bottom: 0.5rem;
}

/** tooltip */
.tooltip.bs-tooltip-bottom { 
  bottom: 0;
}
.tooltip .arrow {
  left: calc( 50% - 0.4em );
}

.draggable {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

.react-select .react-select__menu {
  z-index: 10000;
}

/** fixes for new bootstrap^5.1.3 */
.row > * {
  width: auto;
}
a {
  text-decoration: none;
}